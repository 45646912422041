import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import { Box, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from '@mui/material/styles';
import Footer from 'components/Footer';
import Header from 'components/Header';
import React from 'react';
import { useLocation } from 'react-router-dom';

// Note: you can't put `joyTheme` inside Material UI's `extendMuiTheme(joyTheme)`
// because some of the values in the Joy UI theme refers to CSS variables and
// not raw colors.
const materialTheme = materialExtendTheme({
  typography: {
    fontFamily: 'museo-sans,"Helvetica","Arial",sans-serif',
    h1: {
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 500,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      color: 'rgba(0, 0, 0, 0.75)',
    },
  },
});

export default function Container({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  const fixedFooter = location.pathname === '/accueil';
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider defaultMode="light">
        <CssBaseline enableColorScheme>
          <Stack sx={{ mt: { xs: '106px', sm: '142px' } }}>
            <Header />
            <Box>{children}</Box>
            <Footer fixed={fixedFooter} />
          </Stack>
        </CssBaseline>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
