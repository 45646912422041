import { Stack, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface FooterProps {
  fixed?: boolean;
}

export default function Footer(props: FooterProps) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      sx={{
        px: mobile ? '5%' : '15%',
        position: props.fixed && !mobile ? 'fixed' : 'relative',
        bottom: 0,
        left: 0,
        right: 0,
        background: '#fff',
      }}
    >
      <Stack
        alignItems="center"
        spacing={2}
        sx={{
          width: '100%',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            borderTop: '1px solid #ececec',
            py: 2,
            width: '100%',
            flexDirection: mobile ? 'column' : 'row',
          }}
        >
          <Stack alignItems={mobile ? 'center' : 'start'}>
            <Typography variant="subtitle2" textAlign={mobile ? 'center' : 'left'}>
              Des questions?
            </Typography>
            <Typography variant="body2" textAlign={mobile ? 'center' : 'left'}>
              Appelez-nous au <strong>+1 (418) 832-7929</strong> ou écrivez-nous au{' '}
              <strong>info@nettoyagenetstar.com</strong>
            </Typography>
          </Stack>
          <Stack alignItems={mobile ? 'center' : 'end'}>
            <Typography
              variant="caption"
              sx={{ color: 'rgba(0, 0, 0, 0.56)', p: 2, textAlign: mobile ? 'center' : 'right', fontSize: 9 }}
            >
              <strong>Utilisation des cookies:</strong> Ce site utilise uniquement des cookies à fin d'affichage.
              {!mobile && <br />} Nous ne collectons aucune information permettant de vous identifier.{' '}
              {!mobile && <br />}© {new Date().getFullYear()} Nettoyage Netstar. Tous droits réservés.
            </Typography>
          </Stack>
        </Toolbar>
      </Stack>
    </Stack>
  );
}
