import { Card } from '@mui/joy';

export function ServiceListContainer({ children }: { children: React.ReactNode }) {
  return (
    <Card
      variant="plain"
      sx={{
        borderRadius: 1,
        boxShadow: 'none',
        padding: 2,
        backgroundColor: '#fbfcfe',
      }}
    >
      {children}
    </Card>
  );
}
