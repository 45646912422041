import { Helmet } from 'react-helmet';

interface MetatagsProps {
  title: string;
  description: string;
}

export default function Metatags(props: MetatagsProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
    </Helmet>
  );
}
