import { ChevronRight } from '@mui/icons-material';
import { Button, Card } from '@mui/joy';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import PageTitle from 'components/interface/PageTitle';
import Metatags from 'components/Metatags';
import { ServiceListContainer } from 'components/services/ServiceListContainer';
import { ServiceListItem } from 'components/services/ServiceListItem';
import { ServiceListItemDescription } from 'components/services/ServiceListItemDescription';
import { useNavigate } from 'react-router-dom';

export default function Tiles() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Metatags
          title="Nettoyage de céramique | Nettoyage Netstar"
          description="Pour des espaces en céramique éclatants et des coulis propres à Lévis. Obtenez un devis gratuit pour le nettoyage de vos espaces en céramique et de votre coulis."
        />
        <Grid container display="flex">
          <Grid xs={12} md={6} lg={8}>
            <PageTitle
              title="Nettoyage de céramique"
              description="Retrouvez la beauté de vos espaces en céramique"
              alignment="left"
            />
            <Stack spacing={3}>
              <ServiceListContainer>
                <Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de céramiques à domicile</ServiceListItem>
                    <ServiceListItemDescription>
                      Nous nous déplaçons pour effectuer le nettoyage de votre céramique à votre domicile. Nos outils
                      puissants s'assurent de nettoyer votre céramique et vos coulis en profondeur.
                    </ServiceListItemDescription>
                  </Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de céramiques commercial</ServiceListItem>
                    <ServiceListItemDescription>
                      Faites de vos espaces commerciaux des lieux accueillants et propres pour vos clients et vos
                      employés, en redonnant leur éclat à vos céramiques.
                    </ServiceListItemDescription>
                  </Stack>
                </Stack>
                <Divider sx={{ m: 2 }} />
                <Stack spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack>
                    <Typography variant="subtitle1">Prêt à en profiter?</Typography>
                    <Typography variant="body2">
                      Obtenez un devis <strong style={{ textTransform: 'uppercase' }}>gratuit</strong> pour le nettoyage
                      de vos tapis.
                    </Typography>
                  </Stack>
                  <Button
                    variant="solid"
                    endDecorator={<ChevronRight />}
                    onClick={() => navigate('/contact')}
                    sx={{ width: '200px' }}
                  >
                    Contactez-nous
                  </Button>
                </Stack>
              </ServiceListContainer>
            </Stack>
          </Grid>

          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft" sx={{ p: 3, backgroundColor: '#003b77', color: '#fff' }}>
              <Stack spacing={3}>
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Typography variant="h6" fontWeight={'bold'} color={'#fff'}>
                      Pourquoi nettoyer votre céramique?
                    </Typography>
                    <Typography variant="body2" color={'#ffffffc2'}>
                      Les apparences sont trompeuses. Avec les années, votre céramique pourrait s'être encrassée. Vous
                      pourriez être surpris de revoir la couleur d'origine de votre céramique. Notre technologie de
                      vapeur sous pression donne des résultats étincelants. Et qu'en est-il de vos coulis? Notre
                      clientèle nous le dit souvent: il est très difficile d'entretenir le coulis de céramique. Notre
                      utilisation d'un dégraissant spécialisé, conçu pour les coulis, permet de redonner une apparence
                      d'origine à votre coulis.
                    </Typography>
                  </Stack>
                  <Card
                    variant="solid"
                    sx={{
                      background: 'url("/assets/stock_tile_kitchen.webp")',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      minHeight: '200px',
                    }}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
