import { ChevronRight } from '@mui/icons-material';
import { Button, Card } from '@mui/joy';
import { Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PageTitle from 'components/interface/PageTitle';
import Metatags from 'components/Metatags';
import { ServiceListContainer } from 'components/services/ServiceListContainer';
import { ServiceListItem } from 'components/services/ServiceListItem';
import { ServiceListItemDescription } from 'components/services/ServiceListItemDescription';
import { useNavigate } from 'react-router-dom';

export default function Carpets() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Metatags
          title="Nettoyage de tapis | Nettoyage Netstar"
          description="Pour des tapis et des carpettes propres et hygiéniques à Lévis. Obtenez un devis gratuit pour le nettoyage de vos tapis."
        />
        <Grid container display="flex" spacing={3}>
          <Grid xs={12} md={6} lg={8}>
            <PageTitle
              title="Nettoyage de tapis"
              description="Pour des tapis et des carpettes propres et hygiéniques."
              alignment="left"
            />
            <Stack spacing={2}>
              <ServiceListContainer>
                <Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de tapis à domicile</ServiceListItem>
                    <ServiceListItemDescription>
                      Nous nous déplaçons avec un camion-usine directement à votre dimicile pour effectuer le nettoyage
                      de vos tapis.
                    </ServiceListItemDescription>
                  </Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de tapis commercial</ServiceListItem>
                    <ServiceListItemDescription>
                      Nous nettoyons vos tapis commerciaux pour le bien-être de vos employés et de vos clients. Nous
                      pouvons effectuer le ramassage et la livraison de vos carpettes commerciales, ou nous déplaçons
                      nos camions-usines directement à votre commerce.
                    </ServiceListItemDescription>
                  </Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de carpettes en atelier</ServiceListItem>
                    <ServiceListItemDescription>
                      Avec nos près de 30 ans d'expérience, nous savons comment traiter vos carpettes avec le soin
                      qu'elles méritent. Qu'il s'agisse de laine, soie, viscose ou toute autre fibre naturelle fragile,
                      nous avons les outils et les produits pour les nettoyer en profondeur sans les abîmer.
                      <br /> Vous déposez vous-même votre carpette, ou profitez de notre service de ramassage et de
                      livraison.
                    </ServiceListItemDescription>
                  </Stack>
                </Stack>
                <Divider sx={{ m: 2 }} />
                <Stack spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack>
                    <Typography variant="subtitle1">Prêt à en profiter?</Typography>
                    <Typography variant="body2">
                      Obtenez un devis <strong style={{ textTransform: 'uppercase' }}>gratuit</strong> pour le nettoyage
                      de vos tapis.
                    </Typography>
                  </Stack>
                  <Button
                    variant="solid"
                    endDecorator={<ChevronRight />}
                    onClick={() => navigate('/contact')}
                    sx={{ width: '200px' }}
                  >
                    Contactez-nous
                  </Button>
                </Stack>
              </ServiceListContainer>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft" sx={{ p: 3, backgroundColor: '#003b77', color: '#fff' }}>
              <Stack spacing={3}>
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Typography variant="h6" fontWeight={'bold'} color={'#fff'}>
                      Pourquoi laver mes tapis et carpettes?
                    </Typography>
                    <Stack>
                      <Typography variant="subtitle2">Plus que de la propreté</Typography>
                      <Typography variant="body2" color={'#ffffffc2'}>
                        Au-delà de se débarrasser d'une tache, nettoyer vos tapis améliore votre qualité de vie. Vos
                        tapis peuvent renfermer des bactéries, des acariens, des moisissures et des allergènes. En plus
                        de les rendre plus propres, notre nettoyage à la vapeur combiné à des produits anti-allergènes
                        de haute qualité débarrasse vos tapis de ces mauvais éléments, permettant à votre famille de
                        mieux respirer.
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle2">Cure rajeunissante</Typography>
                      <Typography variant="body2" color={'#ffffffc2'}>
                        Notre technologie de camions-usines nettoie en profondeur le tapis, pour lui redonner un air de
                        jeunesse. Vos tapis seront comme neufs et le resteront plus longtemps.
                      </Typography>
                    </Stack>
                  </Stack>
                  <Card
                    variant="solid"
                    sx={{
                      background: 'url("/assets/stock_carpet_teenage_dog.webp")',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      minHeight: '400px',
                    }}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
