import { ChevronRight } from '@mui/icons-material';
import { Button, Card } from '@mui/joy';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import PageTitle from 'components/interface/PageTitle';
import Metatags from 'components/Metatags';
import { ServiceListContainer } from 'components/services/ServiceListContainer';
import { ServiceListItem } from 'components/services/ServiceListItem';
import { ServiceListItemDescription } from 'components/services/ServiceListItemDescription';
import { useNavigate } from 'react-router-dom';

export default function Commercial() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Metatags
          title="Entretien commercial | Nettoyage Netstar"
          description="Pour un entretien clé en main de vos espaces commerciaux à Lévis. Obtenez un devis gratuit pour l'entretien de vos bureaux."
        />
        <Grid container display="flex">
          <Grid xs={12} md={6} lg={8}>
            <PageTitle
              title="Entretien commercial"
              description="Du clé en main aux besoins spécifiques de votre lieu d'affaires."
              alignment="left"
            />
            <Stack spacing={2}>
              <ServiceListContainer>
                <Stack>
                  <Stack>
                    <ServiceListItem>Lavage de vitres</ServiceListItem>
                    <ServiceListItemDescription>
                      Dans le cadre d'un entretien régulier ou ponctuel, nous prenons en charge le lavage de vos vitres
                      d'immeubles commerciaux.
                    </ServiceListItemDescription>
                  </Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de tapis</ServiceListItem>
                    <ServiceListItemDescription>
                      Si vous prenez soin de vos tapis à la maison, il en va de même pour vos tapis commerciaux. Nous
                      offrons le nettoyage de vos tapis sur place avec nos camions-usines. Nous pouvons également
                      récupérer vos carpettes pour les nettoyer à notre atelier avant de vous les retourner.
                    </ServiceListItemDescription>
                  </Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de vos ameublements: chaises, fauteuils, etc.</ServiceListItem>
                    <ServiceListItemDescription>
                      Nous effectuons le nettoyage de vos meubles rembourrés, qu'il s'agisse des banquettes de votre
                      restaurant, des sièges de la salle d'attente de votre clinique ou des fauteuils de votre bureau.
                    </ServiceListItemDescription>
                  </Stack>
                  <Stack>
                    <ServiceListItem>Entretien régulier</ServiceListItem>
                    <ServiceListItemDescription>
                      Nous offrons le service d'entretien réguliers de vos espaces commerciaux. Nos clients satisfaits
                      ont tous types de commerces, comme des banques, pharmacies, bureaux de notaires, bureaux
                      d'architectes, etc. Nous effectuons également l'entretien des espaces communs des condos. Les
                      services que nous offrons lors de ces entretiens incluent entre autres:
                    </ServiceListItemDescription>
                    <Stack sx={{ pl: 3, pb: 2 }}>
                      <Stack>
                        <ServiceListItem>Lavage de planchers</ServiceListItem>
                        <ServiceListItem>Entretien des salles de bain</ServiceListItem>
                        <ServiceListItem>Époussetage de bureaux</ServiceListItem>
                        <ServiceListItem>Décapage de plancher</ServiceListItem>
                        <ServiceListItem>Balayage et aspirateur</ServiceListItem>
                        <ServiceListItem>Tout autre besoin spécifique...</ServiceListItem>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack>
                    <ServiceListItem>Autres services disponibles sur mesure</ServiceListItem>
                    <ServiceListItemDescription>
                      Appelez-nous pour nous expliquer vos besoins en termes d'entretien commercial. Nous nous ferons un
                      plaisir de vous construire une solution sur mesure.
                    </ServiceListItemDescription>
                  </Stack>
                </Stack>
                <Divider sx={{ m: 2 }} />
                <Stack spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack>
                    <Typography variant="subtitle1">Prêt à en profiter?</Typography>
                    <Typography variant="body2">
                      Obtenez un devis <strong style={{ textTransform: 'uppercase' }}>gratuit</strong> pour le nettoyage
                      de vos tapis.
                    </Typography>
                  </Stack>
                  <Button
                    variant="solid"
                    endDecorator={<ChevronRight />}
                    onClick={() => navigate('/contact')}
                    sx={{ width: '200px' }}
                  >
                    Contactez-nous
                  </Button>
                </Stack>
              </ServiceListContainer>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft" sx={{ p: 3, backgroundColor: '#003b77', color: '#fff' }}>
              <Stack spacing={3}>
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Typography variant="h6" fontWeight={'bold'} color={'#fff'}>
                      Service clé en main
                    </Typography>
                    <Typography variant="body2" color={'#ffffffc2'}>
                      Que ce soit pour un entretien quotidien, hebdomadaire ou tout simplement un grand ménage, notre
                      équipe dévouée prendra soin de votre aire de travail, donnant un air de travail sain et propre à
                      vos employés et clients.
                    </Typography>
                  </Stack>
                  <Card
                    variant="solid"
                    sx={{
                      background: 'url("/assets/stock_office.webp")',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      minHeight: '200px',
                    }}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
