import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MailForm from 'components/contact/MailForm';
import PageTitle from 'components/interface/PageTitle';
import Metatags from 'components/Metatags';

export default function Contact() {
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Metatags
          title="Contactez-nous | Nettoyage Netstar"
          description="Vous avez des questions concernant un de nos services de Nettoyage? Nous sommes là pour vous aider. Sommettez une demande par courriel ou par téléphone."
        />
        <Grid container>
          <Grid xs={12} md={12} lg={12}>
            <PageTitle
              title="Contactez-nous"
              description="Vous avez des questions concernant un de nos services de Nettoyage? Nous sommes là pour vous aider. N'hésitez-pas à nous soumettre une demande à l'aide du formulaire ci-dessous ou de nous contacter par
                téléphone ou courriel."
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} sx={{ p: 2 }}>
            <Typography variant="h6">Remplissez le formulaire</Typography>
            <Typography variant="body2">Nous vous répondrons dans les 48 heures ouvrables.</Typography>
            <MailForm />
          </Grid>
          <Grid xs={12} md={6} lg={6} sx={{ p: 2 }}>
            <Stack spacing={3}>
              <Stack spacing={1}>
                <Stack>
                  <Typography variant="h6">Appelez-nous</Typography>
                  <Typography variant="body2">
                    Vous pouvez nous joindre du lundi au vendredi, entre 9h et 17h.
                  </Typography>
                </Stack>
                <Typography variant="subtitle1">+1 (418) 832-7929</Typography>
              </Stack>
              <Stack spacing={1}>
                <Stack>
                  <Typography variant="h6">Envoyez-nous un courriel</Typography>
                  <Typography variant="body2">
                    Nous vous répondrons dans un délais de 24h à 48h, les jours ouvrables.
                  </Typography>
                </Stack>
                <Typography variant="subtitle1">info@nettoyagenetstar.com</Typography>
              </Stack>
              <Stack spacing={1}>
                <Stack>
                  <Typography variant="h6">Notre atelier sur rendez-vous</Typography>
                  <Typography variant="body2">
                    <strong>Sur rendez-vous seulement</strong>, vous pouvez venir nous voir à notre atelier pour nous
                    déposer vos items à nettoyer.
                  </Typography>
                </Stack>
                <Typography variant="subtitle1">4364 Avenue Saint-Augustin, Lévis, QC G6Z 8K1</Typography>
                <Stack alignItems={'center'} justifyContent={'center'} spacing={2}>
                  <img
                    src="/assets/steeve_esteban_garage.webp"
                    width={'100%'}
                    alt="Esteban et Steeve Boily devant leur atelier et un camion-usine"
                    style={{ borderRadius: '5px' }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
