import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/joy';
import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Metatags from 'components/Metatags';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();
  const yearsOfService = new Date().getFullYear() - new Date(new Date().setFullYear(1997)).getFullYear();

  return (
    <Stack
      sx={{
        backgroundImage: 'url("/assets/stock_sofa.webp")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // p: 5,
        px: { xs: '5%', sm: '15%' },
        height: 'calc(100vh - 250px)',
        justifyContent: 'center',
      }}
    >
      <Metatags
        title="Nettoyage Netstar"
        description="Nettoyage Netstar, entreprise familiale de nettoyage de tapis, meubles rembourrés et céramiques à Lévis, QC."
      />
      <Grid container spacing={2}>
        <Grid xs={12} md={8} lg={8}>
          <Typography variant="h1" textAlign="left">
            À votre service depuis <span style={{ color: '#0093ab' }}>{yearsOfService} ans.</span>
          </Typography>
        </Grid>
        <Grid xs={12} md={6} lg={6}>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography variant="body2" sx={{ color: '#515960', fontSize: '18px' }}>
                Vous nous faites confiance depuis maintenant plus de {yearsOfService} ans pour le nettoyage de vos{' '}
                <strong>tapis</strong>, de vos <strong>meubles rembourrés</strong> et de vos <strong>céramiques</strong>
                .
              </Typography>
              <Typography variant="body2" sx={{ color: '#515960', fontSize: '18px' }}>
                Nettoyage Netstar, c'est une entreprise familiale fière de vous offrir un service de qualité à Lévis et
                les environs.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <Button variant="solid" endDecorator={<ArrowForwardIosIcon />} onClick={(e) => navigate('/contact')}>
                  Contactez-nous
                </Button>
                <Typography variant="caption" sx={{ color: '#515960' }}>
                  Demandez un estimé <strong>gratuit</strong>!
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
