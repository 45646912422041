import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PageTitle from 'components/interface/PageTitle';
import Metatags from 'components/Metatags';

export default function About() {
  const yearsOfService = new Date().getFullYear() - new Date(new Date().setFullYear(1997)).getFullYear();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Metatags
          title="À propos | Nettoyage Netstar"
          description="Nettoyage Netstar, entreprise familiale de nettoyage de tapis, meubles rembourrés et céramiques à Lévis, QC - fondée en 1998 et à votre service depuis plus de 25 ans."
        />
        <Grid container>
          <Grid xs={12} md={12} lg={12}>
            <PageTitle
              title="À propos de Nettoyage Netstar"
              description={`Nous sommes une entreprise familiale d'ici, à votre service depuis ${yearsOfService} ans.`}
            />
          </Grid>
          <Grid xs={12} md={6} lg={5} sx={{ p: 2 }}>
            <img
              src="/assets/steeve_et_esteban_boily.webp"
              width={'100%'}
              alt="Esteban et Steeve Boily"
              style={{ borderRadius: '5px' }}
            />
            <Typography variant="caption">Esteban (fils) et Steeve Boily (propriétaire)</Typography>
          </Grid>
          <Grid xs={12} md={6} lg={7} sx={{ p: 2 }}>
            <Stack spacing={1}>
              <Typography variant="h3">{yearsOfService} ans d'expérience</Typography>
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography variant="h5">1997</Typography>
                  <Typography variant="body2">
                    En 1997, à Québec, Steeve Boily commence à travailler pour une entreprise locale en nettoyage de
                    tapis. C'est là qu'il bâtit son expertise pour le nettoyage à l'aide d'un camion-usine, et sa
                    passion pour la clientèle.
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h5">2000</Typography>
                  <Typography variant="body2">
                    En 2000, Steeve débute à son compte en tant que Nettoyage Netstar. Il offre alors ses services de
                    nettoyage de tapis et meubles rembourrés à la clientèle résidentielle et commerciale de Lévis et ses
                    environs.
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h5">2015</Typography>
                  <Typography variant="body2">
                    Toujours fier des performances inégalés du nettoyage par camion-usine, Steeve fait l'acquisition
                    d'une unité mobile supplémentaire. Cette unité lui permettra de se démarquer au commercial, lui
                    permettant d'effectuer des contrats importants de nettoyage de tapis en moins de temps en opérant
                    deux camions en simultanné.
                  </Typography>
                  <Typography variant="body2">
                    Suite à une demande grandissante de sa clientèle, ll développe alors une nouvelle expertise en
                    nettoyage de céramiques. Il acquiert des outils spécialisés et la connaissance pour faire face à la
                    difficulté de nettoyer les joints de céramiques et observe des résultats impressionnants, en faisant
                    alors un service phare de l'entreprise.
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h5">2019</Typography>
                  <Typography variant="body2">
                    C'est en 2019 qu'Esteban, le fils cadet de Steeve, se joint à l'entreprise familiale en débutant sa
                    formation. Steeve lui transmet son savoir-faire acquis depuis ses débuts, lui permettant de devenir
                    lui-même un spécialiste en tous types de surfaces. Esteban travail maintenant à temps plein pour
                    l'entreprise, apportant avec lui son talent naturel pour le service à la clientèle, se démarquant
                    par sa courtoisie et son sourire.
                  </Typography>
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="h5">Aujourd'hui</Typography>
                  <Typography variant="body2">
                    Steeve et Esteban continuent à servir la clientèle de Lévis et des environs avec la même passion et
                    professionnalisme qui les caractérise.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
