import { ChevronRight } from '@mui/icons-material';
import { Button, Card } from '@mui/joy';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import PageTitle from 'components/interface/PageTitle';
import Metatags from 'components/Metatags';
import { ServiceListContainer } from 'components/services/ServiceListContainer';
import { ServiceListItem } from 'components/services/ServiceListItem';
import { ServiceListItemDescription } from 'components/services/ServiceListItemDescription';
import { useNavigate } from 'react-router-dom';

export default function Furniture() {
  const navigate = useNavigate();
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 2,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Metatags
          title="Nettoyage de meubles rembourrés | Nettoyage Netstar"
          description="Pour des meubles rembourrés propres et sains à Lévis. Obtenez un devis gratuit pour le nettoyage de vos meubles."
        />
        <Grid container display="flex">
          <Grid xs={12} md={6} lg={8}>
            <PageTitle
              title="Nettoyage de meubles rembourrés"
              description="Pour des meubles propres et en santé"
              alignment="left"
            />
            <Stack spacing={3}>
              <ServiceListContainer>
                <Stack>
                  <Stack>
                    <ServiceListItem>Nettoyage de meubles rembourrés à domicile</ServiceListItem>
                    <ServiceListItemDescription>
                      Nous nous déplaçons pour effectuer le nettoyage de vos meubles rembourrés à votre domicile. Nous
                      nettoyons tous types de meubles dont:
                      <ul>
                        <li>Chaises</li>
                        <li>Divans / Sofa</li>
                        <li>Canapé</li>
                        <li>Causeuse</li>
                        <li>Fauteuil</li>
                        <li>Matelas</li>
                      </ul>
                      Pour retrouver une aise optimale l'orsque vous vous asseyez devant le téléviseur, nous sommes la
                      solution!
                    </ServiceListItemDescription>
                  </Stack>
                </Stack>
                <Divider sx={{ m: 2 }} />
                <Stack spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack>
                    <Typography variant="subtitle1">Prêt à en profiter?</Typography>
                    <Typography variant="body2">
                      Obtenez un devis <strong style={{ textTransform: 'uppercase' }}>gratuit</strong> pour le nettoyage
                      de vos tapis.
                    </Typography>
                  </Stack>
                  <Button
                    variant="solid"
                    endDecorator={<ChevronRight />}
                    onClick={() => navigate('/contact')}
                    sx={{ width: '200px' }}
                  >
                    Contactez-nous
                  </Button>
                </Stack>
              </ServiceListContainer>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card variant="soft" sx={{ p: 3, backgroundColor: '#003b77', color: '#fff' }}>
              <Stack spacing={3}>
                <Stack spacing={3}>
                  <Stack spacing={1}>
                    <Typography variant="h6" fontWeight={'bold'} color={'#fff'}>
                      Pourquoi laver mes meubles rembourrés?
                    </Typography>
                    <Typography variant="body2" color={'#ffffffc2'}>
                      Vos meubles font beaucoup pour vous. Ils vous supportent, vous bercent, vous reposent... Ils
                      méritent à leur tour ce qu'il y a de mieux. Vos meubles sont susceptibles de laisser place à des
                      taches, des odeurs et de la saleté, même impercevables à l'oeil nu! Peu importe le recouvrement :
                      cuir, fibres synthétiques et naturelles, polypropylène, oléfine, etc, vos meubles doivent être
                      entretenus régulièrement pour assurer leur longévité et la qualité de votre environnement.
                    </Typography>
                  </Stack>
                  <Card
                    variant="solid"
                    sx={{
                      background: 'url("/assets/stock_couch_clean.webp")',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      minHeight: '200px',
                    }}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
