import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Stack, Typography } from '@mui/material';

export function ServiceListItem({ children }: { children: React.ReactNode }) {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <ChevronRightIcon />
      <Typography variant="h6" fontWeight={'bold'}>
        {children}
      </Typography>
    </Stack>
  );
}
