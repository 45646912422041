import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography, useColorScheme } from '@mui/joy';
import {
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../svgs/logo_white.svg';

interface TabContent {
  label: string;
  value: string;
  children?: TabContent[];
}

export default function Header() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { setMode } = useColorScheme();

  useEffect(() => {
    setMode('light');
  }, [setMode]);

  const [shouldShowMobileMenu, setShouldShowMobileMenu] = useState<boolean>(false);
  const [shouldShowMobileSubMenu, setShouldShowMobileSubMenu] = useState<boolean>(false);
  const [anchorElSubMenu, setAnchorElSubMenu] = useState<null | HTMLElement>(null);
  const [subMenuItems, setSubMenuItems] = useState<TabContent[]>([]);

  const handleOpenNavMenu = () => {
    setShouldShowMobileMenu(true);
  };

  const handleCloseNavMenu = () => {
    if (shouldShowMobileMenu) setShouldShowMobileMenu(false);
  };

  const handleOpenSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (mobile) setShouldShowMobileSubMenu(true);
    else setAnchorElSubMenu(event.currentTarget);
  };

  const handleCloseSubMenu = () => {
    if (mobile) setShouldShowMobileSubMenu(false);
    else setAnchorElSubMenu(null);
  };

  const navigate = useNavigate();

  const navigateFromMenu = (path: string) => {
    navigate(path);
    handleCloseNavMenu();
    handleCloseSubMenu();
  };

  // Navigation tabs
  const location = window.location;
  const rootPath = location.pathname;

  const handleTabClick = (e: React.SyntheticEvent<Element, Event>, tab: TabContent) => {
    handleCloseNavMenu();
    if (tab.children) {
      setSubMenuItems(tab.children);
      handleOpenSubMenu(e as React.MouseEvent<HTMLElement>);
      return;
    }
    navigate(`/${tab.value}`);
  };

  const handleTabHover = (e: React.SyntheticEvent<Element, Event>, tab: TabContent) => {
    if (tab.children) {
      setSubMenuItems(tab.children);
      handleOpenSubMenu(e as React.MouseEvent<HTMLElement>);
    }
  };

  const handleTabLeave = (e: React.SyntheticEvent<Element, Event>) => {
    const relatedTarget = (e.nativeEvent as MouseEvent).relatedTarget as HTMLElement;
    if (relatedTarget && relatedTarget.closest('#menu-sub-nav')) {
      return;
    }
    handleCloseSubMenu();
  };

  const tabs: TabContent[] = [
    { label: 'Accueil', value: 'accueil' },
    {
      label: 'Nos Services',
      value: 'services',
      children: [
        { label: 'Nettoyage de tapis', value: 'services/tapis' },
        { label: 'Nettoyage de céramiques', value: 'services/ceramique' },
        { label: 'Nettoyage de meubles', value: 'services/meubles' },
        { label: 'Nettoyage commercial', value: 'services/commercial' },
      ],
    },
    { label: 'À propos de nous', value: 'a-propos' },
  ];

  return (
    <Stack>
      <AppBar
        position="static"
        sx={{
          background: '#003b77',
          boxShadow: 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            py: mobile ? 1 : 2,
            px: mobile ? '5%' : '15%',
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{ width: mobile ? '100px' : '175px', cursor: 'pointer' }}
            onClick={() => (window.location.href = '/accueil')}
          />
          <Stack>
            <Stack direction="row" spacing={1} justifyContent="end">
              <Typography fontWeight={300} fontSize={12} textTransform="uppercase" sx={{ color: '#fff' }}>
                Téléphone
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="end" alignItems="center">
              <LocalPhoneIcon fontSize={mobile ? 'small' : 'medium'} />
              <Typography fontWeight={600} fontSize={{ md: 17, sm: 13 }} sx={{ color: '#fff' }}>
                +1 (418) 832-7929
              </Typography>
            </Stack>
          </Stack>
        </Toolbar>
        <Toolbar
          disableGutters
          sx={{
            justifyContent: 'space-between',
            background: '#001e3c',
            px: mobile ? '5%' : '15%',
            minHeight: '48px!important',
            alignItems: 'stretch',
          }}
        >
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              id="mobile-menu-appbar-button"
              size="large"
              aria-label="open navigation"
              aria-controls="menu-appbar-mobile"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Tabs
            value={hasTabMatch(tabs, rootPath)}
            aria-label="navigation nettoyage netstar"
            textColor="inherit"
            TabIndicatorProps={{ style: { background: '#fff' } }}
            sx={{ display: { xs: 'none', md: 'flex' } }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab}
                onClick={(e) => handleTabClick(e, tab)}
                onMouseEnter={(e) => handleTabHover(e, tab)}
                onMouseLeave={handleTabLeave}
              />
            ))}
          </Tabs>
          <Menu
            id="menu-sub-nav"
            anchorEl={anchorElSubMenu}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={Boolean(anchorElSubMenu)}
            onClose={handleCloseSubMenu}
            onMouseLeave={handleTabLeave}
            sx={{
              display: { xs: 'none', md: 'block' },
              borderRadius: 0,
              cursor: 'pointer',
            }}
          >
            {subMenuItems.map((tab) => (
              <MenuItem key={tab.value} onClick={(e) => navigateFromMenu(tab.value)}>
                <Typography textAlign="center">{tab.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Stack justifyContent="center" alignItems="center" sx={{ background: '#0068b1', width: '165px' }}>
            <Button
              sx={{
                color: '#fff',
                px: 3,
                // opacity: rootPath === 'contact' ? 1 : 0.6,
              }}
              onClick={() => navigate('/contact')}
            >
              Nous joindre
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <ClickAwayListener
        onClickAway={(event) => {
          // If user clicks the toggle button or one of its children, do NOT close the menu.
          const target = event.target as HTMLElement;

          // Check if the click originated from the menu icon or its subtree
          if (target.id === 'mobile-menu-appbar-button' || target.closest('#mobile-menu-appbar-button')) {
            return;
          }

          handleCloseNavMenu();
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: '#fff0',
            width: '100%',
            // You could also do minHeight or dynamic sizing as needed
          }}
        >
          <Fade in={shouldShowMobileMenu} timeout={100} unmountOnExit>
            <List
              id="menu-appbar-mobile"
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '100%',
                maxWidth: '100%',
                borderRadius: 0,
                padding: 0,
                margin: 0,
                background: '#fff',
                borderBottom: '1px solid #f0f0f0',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {tabs.map((tab, index) => (
                <>
                  <ListItemButton
                    key={tab.value}
                    onClick={(e) => handleTabClick(e, tab)}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#f0f0f0 !important',
                      },
                    }}
                  >
                    <Typography textAlign="center">{tab.label}</Typography>
                  </ListItemButton>
                  {index < tabs.length - 1 && <Divider variant="middle" />}
                </>
              ))}
            </List>
          </Fade>
        </Box>
      </ClickAwayListener>
      <ClickAwayListener
        onClickAway={(event) => {
          // If user clicks the toggle button or one of its children, do NOT close the menu.
          const target = event.target as HTMLElement;

          // Check if the click originated from the menu icon or its subtree
          if (target.id === 'menu-appbar-mobile' || target.closest('#menu-appbar-mobile')) {
            return;
          }

          handleCloseSubMenu();
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: '#fff0',
            width: '100%',
            // You could also do minHeight or dynamic sizing as needed
          }}
        >
          <Fade in={shouldShowMobileSubMenu} timeout={100} unmountOnExit>
            <List
              id="menu-sub-nav-mobile"
              sx={{
                display: { xs: 'block', md: 'none' },
                width: '100%',
                maxWidth: '100%',
                borderRadius: 0,
                padding: 0,
                margin: 0,
                background: '#fff',
                borderBottom: '1px solid #f0f0f0',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              }}
            >
              {subMenuItems.map((tab, index) => (
                <>
                  <ListItemButton key={tab.value} onClick={(e) => navigateFromMenu(tab.value)}>
                    <Typography textAlign="center">{tab.label}</Typography>
                  </ListItemButton>
                  {index < subMenuItems.length - 1 && <Divider variant="middle" />}
                </>
              ))}
            </List>
          </Fade>
        </Box>
      </ClickAwayListener>
    </Stack>
  );
}

const hasTabMatch = (tabValues: TabContent[], path: string) => {
  for (const tab of tabValues) {
    if (path.includes(tab.value)) {
      return tab;
    }
  }

  return false;
};
