import { Divider, Stack, Typography } from '@mui/material';

export interface PageTitleProps {
  title: string;
  description: string;
  alignment?: 'center' | 'left' | 'right';
}

export default function PageTitle(props: PageTitleProps) {
  const alignment = props.alignment || 'center';
  const flexAlign = alignment === 'center' ? 'center' : alignment === 'left' ? 'start' : 'end';
  return (
    <Stack spacing={1} sx={{ my: 3 }} alignItems={flexAlign} justifyContent={flexAlign}>
      <Typography variant="h1" sx={{ color: '#444' }} textAlign={alignment}>
        {props.title}
      </Typography>
      <Typography variant="subtitle1" textAlign={alignment} sx={{ width: '80%', lineHeight: '1.4', color: '#444' }}>
        {props.description}
      </Typography>
      <Divider sx={{ width: '10%', p: 1 }} />
    </Stack>
  );
}
