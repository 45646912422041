import { Stack, Typography } from '@mui/material';

export function ServiceListItemDescription({ children }: { children?: React.ReactNode }) {
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Typography variant="body1" sx={{ pl: 3, mb: 1 }}>
        {children}
      </Typography>
    </Stack>
  );
}
